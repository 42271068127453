<template>
  <div>
    <!--LOGIN MODAL-->
    <b-modal
      ref="login-modal"
      centered
      id="modal-1"
      no-close-on-backdrop
      no-close-on-esc
      backdroptitle="Login"
      hide-footer
      hide-header-close
    >
      <b-form @submit.prevent="login">
        <b-form-group
          id="input-group-1"
          label="Email address:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="loginform.email"
            type="email"
            placeholder="Enter email"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Password:" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="loginform.password"
            placeholder="Enter password"
            required
            type="password"
          ></b-form-input>
        </b-form-group>

        <b-alert class="message" show v-if="loginform.loginerror">{{
          loginform.loginerror
        }}</b-alert>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
    </b-modal>
    <!-- END LOGIN MODAL -->

    <!--ADD/EDIT event MODAL -->
    <b-modal
      v-model="eventModal"
      centered
      id="modal-2"
      no-close-on-backdrop
      no-close-on-esc
      backdroptitle="EVENT"
      :title="event_edited.document_id"
      hide-footer
      hide-header-close
      size="xl"
    >
      <b-form-group
        id="group-event-url-name"
        v-if="event_edited.document_id != 'new'"
        label="Url:"
        label-for="event-url_name"
      >
        <b-form-input
          id="event-url-name"
          v-model="event_url_name_edited"
          type="text"
          placeholder="name in url"
        ></b-form-input>
        <p>
          <i
            >https://lite.owncast.live/{{ $route.params.tenantname }}/{{
              event_url_name_edited
            }}</i
          >
        </p>
      </b-form-group>
      <b-form @submit.prevent="event_op">
        <p>
          LOCALIZATIONS: (click to remove, if you removed it accidentally you
          can re-add the locale, text will not be lost)
        </p>
        <b-button
          class="ml-1"
          v-bind:key="loc + 'Local'"
          v-for="loc in event_edited.localizations"
          @click="
            for (var i in event_edited.localizations) {
              if (event_edited.localizations[i] == loc) {
                event_edited.localizations.splice(i, 1);
                break;
              }
            }
          "
          >{{ loc }}</b-button
        >
        <b-form-select
          v-model="add_local_selection"
          :options="locales"
        ></b-form-select>
        <b-button
          @click="event_edited.localizations.push(add_local_selection)"
          class="ml-1"
          variant="success"
          >ADD LOCALE</b-button
        >

        <b-form-group
          id="group-event-title"
          label="Title:"
          label-for="event-title"
        >
          <b-form-input
            id="event-title"
            v-for="l in event_edited.localizations"
            v-bind:key="l + 'title'"
            v-model="event_edited.title[l]"
            type="text"
            :placeholder="l"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="group-event-state"
          label="State:"
          label-for="event-state"
        >
          <b-form-select
            id="event-state"
            v-model="event_edited.state"
            :options="event_state_options"
            placeholder="Enter event title"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="group-event-date-time"
          label="Event date and time (UTC)"
          label-for="event-date-time"
        >
          <b-form-datepicker
            id="event-date"
            v-if="event_edited.event_date_time"
            v-model="event_edited.event_date_time.date"
            class="mb-2"
          ></b-form-datepicker>
          <b-form-timepicker
            id="event-time"
            v-if="event_edited.event_date_time"
            v-model="event_edited.event_date_time.time"
            class="mb-2"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group
          id="group-event-published"
          label="Publish event:"
          label-for="event-published"
        >
          <b-form-checkbox
            id="event-published"
            v-model="event_edited.published"
          >
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          id="group-event-generic-message"
          label="Generic Alert:"
          label-for="event-generic-message"
        >
          <b-form-input
            id="event-title"
            v-for="l in event_edited.localizations"
            v-bind:key="l + 'genmsg'"
            v-model="event_edited.generic_message[l]"
            type="text"
            :placeholder="l"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="group-event-description"
          label="Description:"
          label-for="event-description"
        >
          <b-form-checkbox
            id="event-description-show"
            v-model="event_edited.description.show"
          >
            Enable description section
          </b-form-checkbox>
          <b-form-input
            id="event-description-title"
            v-for="l in event_edited.localizations"
            v-bind:key="l + 'desctitle'"
            v-model="event_edited.description.title[l]"
            type="text"
            :placeholder="l + ' title'"
          ></b-form-input>
          <b-form-input
            id="event-description-text"
            v-for="l in event_edited.localizations"
            v-bind:key="l + 'desctext'"
            v-model="event_edited.description.text[l]"
            type="text"
            :placeholder="l + ' text'"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="group-event-coming"
          label="Coming section:"
          label-for="event-coming"
        >
          <b-form-input
            id="event-coming-backgroundImage"
            v-for="l in event_edited.localizations"
            v-bind:key="l + 'comingbkgrimg'"
            v-model="event_edited.coming.backgroundImage[l]"
            type="text"
            :placeholder="l + ' background image url'"
          ></b-form-input>
          <b-form-input
            id="event-coming-message"
            v-for="l in event_edited.localizations"
            v-bind:key="l + 'comingmsg'"
            v-model="event_edited.coming.message[l]"
            type="text"
            :placeholder="l + ' coming message'"
          ></b-form-input>
          <b-form-input
            id="event-coming-textColor"
            v-model="event_edited.coming.textColor"
            type="color"
            placeholder="Text color"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="event_edited.PIIprivacy"
          id="group-event-PII"
          label="Require PII:"
          label-for="event-PII"
        >
          <b-form-checkbox
            id="event-requirePII"
            v-model="event_edited.requirePII"
          >
            Require user information (name, surname, email)
          </b-form-checkbox>
          <b-form-input
            id="event-PIIprivacy-text"
            v-for="l in event_edited.localizations"
            v-bind:key="l + 'PIIprivacytext'"
            v-model="event_edited.PIIprivacy.text[l]"
            type="text"
            :placeholder="l + ' Privacy text in PII form'"
          ></b-form-input>
          <b-form-input
            id="event-PIIprivacy-linkText"
            v-model="event_edited.PIIprivacy.linkText"
            type="text"
            placeholder="Privacy Link Text"
          ></b-form-input>
          <b-form-input
            id="event-PIIprivacy-linkURL"
            v-model="event_edited.PIIprivacy.linkURL"
            type="text"
            placeholder="Privacy Link URL"
          ></b-form-input>
          <b-form-checkbox
            id="event-requirePIIprivacycheckbox"
            v-model="event_edited.PIIprivacy.requirePrivacyCheckbox"
          >
            Require user to accept policy
          </b-form-checkbox>
          <span v-if="event_edited.PIIextrafields">
            <b-form-input
              id="event-PII-extrafield1"
              v-for="l in event_edited.localizations"
              v-bind:key="l + 'PIIextrafield1'"
              v-model="event_edited.PIIextrafields.extrafield1[l]"
              type="text"
              :placeholder="l + ' Extra field 1'"
            ></b-form-input>
            <b-form-input
              id="event-PII-extrafield2"
              v-for="l in event_edited.localizations"
              v-bind:key="l + 'PIIextrafield2'"
              v-model="event_edited.PIIextrafields.extrafield2[l]"
              type="text"
              :placeholder="l + ' Extra field 2'"
            ></b-form-input>
            <b-form-input
              id="event-PII-extrafield3"
              v-for="l in event_edited.localizations"
              v-bind:key="l + 'PIIextrafield3'"
              v-model="event_edited.PIIextrafields.extrafield3[l]"
              type="text"
              :placeholder="l + ' Extra field 3'"
            ></b-form-input>
            <b-form-input
              id="event-PII-extrafield4"
              v-for="l in event_edited.localizations"
              v-bind:key="l + 'PIIextrafield4'"
              v-model="event_edited.PIIextrafields.extrafield4[l]"
              type="text"
              :placeholder="l + ' Extra field 4'"
            ></b-form-input>
            <b-form-input
              id="event-PII-extrafield5"
              v-for="l in event_edited.localizations"
              v-bind:key="l + 'PIIextrafield5'"
              v-model="event_edited.PIIextrafields.extrafield5[l]"
              type="text"
              :placeholder="l + ' Extra field 5'"
            ></b-form-input
          ></span>
        </b-form-group>
        <b-form-group
          id="group-event-questions"
          label="Questions:"
          label-for="event-questions"
        >
          <b-form-checkbox
            id="event-questions"
            v-model="event_edited.questions"
          >
            Enable question section
          </b-form-checkbox>
          <b-form-checkbox
            id="event-questionsopened"
            v-model="event_edited.questionsopened"
          >
            Accept new questions
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          id="group-event-sources"
          label="Video sources:"
          label-for="event-sources"
        >
          <b-form-input
            id="event-player-poster-url"
            v-model="event_edited.player_poster_url"
            type="text"
            placeholder="Poster URL in player"
          ></b-form-input>
          <b-table
            striped
            responsive
            hover
            fixed
            :items="event_edited.sources"
            :fields="sources_fields"
          >
            <template #cell(url)="data">
              <p class="font-weight-light overflow-auto">
                <small>{{ data.value }}</small>
              </p>
            </template>
            <template #cell(actions)="data">
              <b-button @click="editSource(data.item.language_code)" size="sm"
                >Edit</b-button
              >
              <b-button
                @click="deleteSource(data.item.language_code)"
                variant="danger"
                size="sm"
                >Delete</b-button
              >
            </template>
            <template #table-caption>
              <p class="mt-3 text-right">
                <b-button @click="editSource('new')" size="sm"
                  >Add source</b-button
                >
              </p>
            </template>
          </b-table>
        </b-form-group>
        <b-form-group
          id="group-event-iframeUrl"
          label="IFrame URL:"
          label-for="event-iframe-url"
        >
          <b-form-input
            id="event-iframe-url"
            v-model="event_edited.iframeUrl"
            type="text"
            placeholder="video iframe url"
          ></b-form-input>
        </b-form-group>
        <b-alert class="message" show v-if="event_edited.error">{{
          event_edited.error
        }}</b-alert>
        <b-button @click="closeEventEditing()" variant="danger">Exit</b-button>

        <b-button @click="saveEvent()" variant="primary">{{
          event_edited.document_id == "new" ? "ADD" : "EDIT"
        }}</b-button>
      </b-form>
    </b-modal>
    <!-- END ADD EDIT EVENT MODAL -->

    <!--SOURCE MODAL-->
    <b-modal
      v-model="sourceModal"
      centered
      id="modal-3"
      no-close-on-backdrop
      no-close-on-esc
      backdroptitle="Source"
      hide-footer
      hide-header-close
    >
      <b-form @submit.prevent="login">
        <b-form-group
          id="source-group-1"
          label="Language_code:"
          label-for="input-source-language-code"
        >
          <b-form-input
            id="input-source-language-code"
            v-model="source_edited.language_code"
            type="text"
            placeholder="Enter language code (ISO 639-1) 2 letter code"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="source-group-2"
          label="Language name:"
          label-for="input-source-language-name"
        >
          <b-form-input
            id="input-source-language-codr"
            v-model="source_edited.language_name"
            type="text"
            placeholder="Enter language name"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="source-group-3"
          label="M3u8 URL"
          label-for="input-source-url"
        >
          <b-form-input
            id="input-source-language-codr"
            v-model="source_edited.url"
            type="text"
            placeholder="Enter language name"
            required
          ></b-form-input>
        </b-form-group>

        <b-alert class="message" show v-if="source_edited.error">{{
          source_edited.error
        }}</b-alert>
        <b-button @click="closeSourceEditing()" variant="danger"
          >Close</b-button
        >
        <b-button @click="saveSource()" variant="primary">{{
          source_edited.language_code == "new" ? "ADD" : "EDIT"
        }}</b-button>
      </b-form>
    </b-modal>
    <!-- END SOURCE MODAL -->

    <!-- VIEW REGISTERED PARTICIPANTs MODAL -->
    <b-modal
      size="xl"
      v-model="participantsModal"
      centered
      id="modal-4"
      no-close-on-backdrop
      no-close-on-esc
      :title="event_participants.doc_id"
      backdroptitle="Users"
      hide-footer
      hide-header-close
    >
      <b-table
        striped
        hover
        bordered
        small
        :items="event_participants.participants"
        :fields="event_participants_fields"
      >
      </b-table>
      <b-button @click="closeUsersViewEditing()" variant="danger"
        >Close</b-button
      >
    </b-modal>

    <!-- END VIEW REGISTERED PARTICIPANTs MODAL -->

    <!-- VIEW VIEWERS COUNTS MODAL -->
    <b-modal
      size="xl"
      v-model="viewersCountModal"
      centered
      id="modal-4"
      no-close-on-backdrop
      no-close-on-esc
      :title="event_viewers_count.doc_id"
      backdroptitle="Viewers Counts"
      hide-footer
      hide-header-close
    >
      <b-table
        striped
        hover
        :items="event_viewers_count.counts"
        :fields="event_viewers_count_fields"
        sort-by="time"
      >
      </b-table>
      <b-button @click="closeViewersCount()" variant="danger">Close</b-button>
    </b-modal>

    <!-- END VIEW REGISTERED PARTICIPANTs MODAL -->

    <!-- MAIN PAGE -->
    <b-container fluid class="">
      <b-row>
        <b-col class="text-justify">
          <b-jumbotron
            :header="
              $route.params.tenantname
                ? $route.params.tenantname.toUpperCase()
                : null
            "
            lead="Tenant Administration"
            >Logged in: {{ user ? user.email : null }}
            <b-button
              size="sm"
              v-if="user"
              variant="outline-primary"
              @click="logOut()"
              >Logout</b-button
            >
          </b-jumbotron>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <p class="mt-3 text-right">
            <b-button @click="editEvent('new')">Add event</b-button>
          </p>

          <b-table striped hover :items="events" :fields="events_fields">
            <template #cell(actions)="data">
              <b-button @click="editEvent(data.item.document_id)" size="sm"
                >EDIT</b-button
              >
              &nbsp;
              <b-button
                @click="getUsersForEvent(data.item.document_id)"
                size="sm"
                >VIEW USERS</b-button
              >
              &nbsp;
              <b-button
                @click="getViewersCountForEvent(data.item.document_id)"
                size="sm"
                >STATS</b-button
              >
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <!-- END MAIN PAGE -->
  </div>
</template>

<style>
@import "https://cdn.myth.theoplayer.com/f7d7a378-012f-4a04-a037-4af7c65bd03d/ui.css";

/* Customization css style */
.theo-primary-color,
.vjs-selected {
  color: #ace1e2 !important;
}

.theo-primary-background {
  color: #000000 !important;
  background-color: #ace1e2 !important;
}

.theo-secondary-color {
  color: #ffffff !important;
}

.theo-secondary-background {
  color: #000000 !important;
  background-color: #ffffff !important;
}

.theo-tertiary-color {
  color: #000000 !important;
}

.theo-tertiary-background {
  color: #ffffff !important;
  background-color: #000000 !important;
}

.player,
.coming,
.description,
.PII {
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5em;
}

.alert {
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5em;
}

.btn.active {
  background-color: #ace1e2 !important;
  color: black !important;
}

.questions {
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5em;
}
</style>

<script>
import * as fb from "./../firebase";

export default {
  data() {
    return {
      fbauth: null,
      user: null,
      loginform: { email: null, password: null, loginerror: null },
      add_local_selection: null,
      locales: [
        { value: null, text: "Please select an option" },
        { value: "en", text: "English" },
        { value: "it", text: "Italiano" },
        { value: "fr", text: "Francais" },
        { value: "de", text: "Deutsch" },
        { value: "zh", text: "Chinese" },
        { value: "ru", text: "Russian" },
        { value: "es", text: "Espanol" },
      ],
      event_edited: {},
      event_url_name_edited: null,
      event_template: {
        localizations: ["en"],
        title: {},
        state: null,
        published: false,
        event_date_time: {
          date: null,
          time: null,
        },
        generic_message: {},
        description: {
          show: false,
          title: {},
          text: {},
        },
        player_poster_url: null,
        questions: false,
        questionsopened: false,
        requirePII: false,
        coming: {
          backgroundImage: {},
          message: {},
          textColor: null,
        },
        PIIextrafields: {
          extrafield1: {},
          extrafield2: {},
          extrafield3: {},
          extrafield4: {},
          extrafield5: {},
        },
        PIIprivacy: {
          text: {},
          linkText: null,
          linkURL: null,
          requirePrivacyCheckbox: false,
        },
        sources: [],
        iframeUrl: "",
      },
      source_template: {
        language_code: null,
        language_name: null,
        order: null,
        url: null,
      },
      sources_fields: [
        {
          key: "language_code",
          sortable: true,
        },
        {
          key: "language_name",
          sortable: true,
        },
        {
          key: "url",
          sortable: false,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
      source_edited: {},
      event_participants_template: {
        doc_id: undefined,
        participants: [],
      },
      event_participants_fields: [
        {
          key: "id",
          sortable: false,
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "surname",
          sortable: true,
        },
        {
          key: "email",
          sortable: true,
        },
        {
          key: "extrafield1",
          sortable: true,
        },
        {
          key: "extrafield2",
          sortable: true,
        },
        {
          key: "extrafield3",
          sortable: true,
        },
        {
          key: "extrafield4",
          sortable: true,
        },
        {
          key: "extrafield5",
          sortable: true,
        },
      ],
      event_participants: {},

      event_viewers_count_template: {
        doc_id: undefined,
        counts: [],
      },
      event_viewers_count_fields: [
        {
          key: "time",
          sortable: true,
        },
        {
          key: "viewers",
          sortable: true,
        },
      ],
      event_viewers_count: {},

      event_state_options: [
        { value: "coming", text: "Coming" },
        { value: "live", text: "Live" },
        { value: "liveIframe", text: "Live Iframe" },

      ],
      events_fields: [
        {
          key: "title",
          sortable: true,
        },
        {
          key: "event_date_time.date",
          label: "Date",
          sortable: true,
        },
        {
          key: "event_date_time.time",
          label: "Time",
          sortable: true,
        },
        {
          key: "state",
          sortable: true,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
      events: [],
      events_sortBy: "title",
      events_sortDesc: false,
    };
  },
  computed: {
    eventModal: function () {
      return this.event_edited.document_id ? true : false;
    },
    sourceModal: function () {
      return this.source_edited.language_code ? true : false;
    },
    participantsModal: function () {
      return this.event_participants.doc_id !== undefined ? true : false;
    },
    viewersCountModal: function () {
      return this.event_viewers_count.doc_id !== undefined ? true : false;
    },
  },
  created() {
    this.event_edited = Object.assign({}, this.event_template);
    this.event_participants = Object.assign(
      {},
      this.event_participants_template
    );
    this.event_viewers_count = Object.assign(
      {},
      this.event_viewers_count_template
    );
  },
  mounted() {
    this.fbauth = fb.auth;

    (this.user = fb.auth.currentUser ? fb.auth.currentUser : null),
      !this.user ? this.$refs["login-modal"].show() : null;

    fb.auth.onAuthStateChanged((user) => {
      this.user = user;
      this.getEvents();
    });
    //LOAD PLAYER SCRIPTS
    let castFwScript = document.createElement("script");
    castFwScript.setAttribute(
      "src",
      "//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1"
    );
    document.head.appendChild(castFwScript);

    let theoScript = document.createElement("script");
    theoScript.setAttribute(
      "src",
      "https://cdn.myth.theoplayer.com/f7d7a378-012f-4a04-a037-4af7c65bd03d/THEOplayer.js"
    );
    document.head.appendChild(theoScript);

    //fb.auth.setPersistence(fb.authPersistence.SESSION)
  },
  beforeDestroy() {},
  methods: {
    login() {
      fb.auth
        .signInWithEmailAndPassword(
          this.loginform.email,
          this.loginform.password
        )
        .then((userCredential) => {
          // Signed in
          this.user = userCredential.user;
          this.$refs["login-modal"].hide();
        })
        .catch((error) => {
          //var errorCode = error.code;
          var errorMessage = error.message;
          this.loginform.loginerror = errorMessage;
        });
    },
    getEvents() {
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((eventdoc) => {
            eventdoc.ref
              .collection("config")
              .doc("0")
              .onSnapshot((configdoc) => {
                let config = configdoc.data();
                config.document_id = eventdoc.id;
                let eventIndex = this.events.findIndex(
                  (x) => x.document_id === eventdoc.id
                );
                eventIndex >= 0 ? this.events.splice(eventIndex, 1) : null;
                this.events.push(config);
              });
          });
        });
    },

    editSource(language_code) {
      if (language_code == "new") {
        this.source_edited = Object.assign({}, this.source_template);
        this.source_edited.language_code = "new";
      } else {
        const sourceToEdit = this.event_edited.sources.find(
          (v) => v.language_code === language_code
        );
        this.source_edited = Object.assign({}, sourceToEdit);
      }
    },
    deleteSource(language_code) {
      let sourceIndex = this.event_edited.sources.findIndex(
        (x) => x.language_code === language_code
      );
      sourceIndex >= 0
        ? this.event_edited.sources.splice(sourceIndex, 1)
        : null;
    },
    saveSource() {
      let sourceIndex = this.event_edited.sources.findIndex(
        (x) => x.language_code === this.source_edited.language_code
      );
      sourceIndex >= 0
        ? this.event_edited.sources.splice(sourceIndex, 1)
        : null;
      this.event_edited.sources.push(this.source_edited);
      this.source_edited = Object.assign({}, this.source_template);
    },
    closeSourceEditing() {
      this.source_edited = this.source_template;
    },
    editEvent(doc_id) {
      if (doc_id == "new") {
        this.event_edited = Object.assign({}, this.event_template);
        this.event_edited.document_id = "new";
      } else {
        const eventToEdit = this.events.find((v) => v.document_id === doc_id);
        //this.event_edited = Object.assign({}, eventToEdit);
        this.event_edited = JSON.parse(JSON.stringify(eventToEdit));
        this.getEventUrlName();
      }
    },
    saveEvent() {
      if (this.event_edited.document_id == "new") {
        fb.db
          .collection("tenants")
          .doc(this.$route.params.tenantname)
          .collection("events")
          .add({})
          .then((docRef) => {
            console.log("Created event with ID: ", docRef.id);
            this.event_edited.document_id = docRef.id;
            docRef
              .collection("config")
              .doc("0")
              .set(this.event_edited)
              .then(() => {
                this.event_edited = Object.assign({}, this.event_template);
                window.location.reload();
              })
              .catch((error) => {
                console.error("Error adding config to event: ", error);
              });
          })
          .catch(function (error) {
            console.error("Error creating event: ", error);
          });
      } else {
        fb.db
          .collection("tenants")
          .doc(this.$route.params.tenantname)
          .collection("events")
          .doc(this.event_edited.document_id)
          .collection("config")
          .doc("0")
          .update(this.event_edited)
          .then(() => {
            this.setEventUrlName(
              this.event_edited.document_id,
              this.event_url_name_edited
            );

            this.event_edited = Object.assign({}, this.event_template);
          })
          .catch((error) => {
            console.error("Error updating event: ", error);
          });
      }
    },
    getEventUrlName() {
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("event_links")
        .where("event_ref", "==", this.event_edited.document_id)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let data = doc.data();
            //console.log(doc.id, " => ", data);
            this.event_url_name_edited = data.url_name;
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    setEventUrlName(doc_id, name) {
      //check event_link doc
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("event_links")
        .where("event_ref", "==", doc_id)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size == 0) {
            fb.db
              .collection("tenants")
              .doc(this.$route.params.tenantname)
              .collection("event_links")
              .doc()
              .set(
                {
                  event_ref: doc_id,
                  url_name: name,
                },
                { merge: true }
              );
          } else {
            querySnapshot.forEach((doc) => {
              doc.ref.set(
                {
                  url_name: name,
                },
                { merge: true }
              );
            });
          }
          this.event_url_name_edited = null;
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          this.event_url_name_edited = null;
        });

      //create if not exist

      //update if exists
    },
    closeEventEditing() {
      this.event_edited = Object.assign({}, this.event_template);
      this.event_url_name_edited = null;
    },
    getViewersCountForEvent(doc_id) {
      this.event_viewers_count.doc_id = doc_id;

      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(doc_id)
        .collection("viewers_count")
        .where("viewers", ">", 0)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((viewercountdoc) => {
            const viewer_count = viewercountdoc.data();
            viewer_count.id = viewercountdoc.id;
            viewer_count.time = new Date(
              parseInt(viewercountdoc.id, 10)
            ).toGMTString();
            viewer_count.created = viewercountdoc.createTime;
            viewer_count.updated = viewercountdoc.updateTime;

            this.event_viewers_count.counts.push(viewer_count);
          });
        });
    },
    closeViewersCount() {
      this.event_viewers_count.counts.splice(
        0,
        this.event_viewers_count.counts.length
      );
      this.event_viewers_count = Object.assign(
        {},
        this.event_viewers_count_template
      );
    },
    getUsersForEvent(doc_id) {
      this.event_participants.doc_id = doc_id;

      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(doc_id)
        .collection("viewers")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((viewerdoc) => {
            const viewer = viewerdoc.data();
            viewer.id = viewerdoc.id;
            viewer.created = viewerdoc.createTime;
            viewer.updated = viewerdoc.updateTime;

            this.event_participants.participants.push(viewer);
          });
        });
    },
    closeUsersViewEditing() {
      this.event_participants.participants.splice(
        0,
        this.event_participants.participants.length
      );
      this.event_participants = Object.assign(
        {},
        this.event_participants_template
      );
    },

    logOut() {
      fb.auth
        .signOut()
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.log("error during logout: ", error);
        });
    },

    loadPlayer() {
      if (this.player == null) {
        this.player = new window.THEOplayer.Player(
          document.getElementById("playerdiv"),
          {
            libraryLocation:
              "https://cdn.myth.theoplayer.com/f7d7a378-012f-4a04-a037-4af7c65bd03d",
            //mutedAutoplay: "all",
            license:
              "sZP7IYe6T6P60lXK3uPg3Zzc3S0LFSakCLR-CKBiIOzz3SezIu1lClC_0Qf6FOPlUY3zWokgbgjNIOf9fKBk3l0LIDaoFDaz3La-3Qak3Ok13DhZFDBZ3l0L3K0k0KBz0ZfVfK4_bQgZCYxNWoryIQXzImf90SCc0u0kTSfi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3l5rTuhL3SBL3S5ZFOPeWok1dDrLYtA1Ioh6TgV6UQ1gWtAVCYggb6rlWoz6FOPVWo31WQ1qbta6FOPqUorlCY3zFD41btepUo46FKXibOfVfKcNUQhpWtUpCoXLUOrVdYIgfgzVfKxqWDXNWG3ybojkbK3gflNWfGxEIDjiWQXrIYfpCoj-f6i6WQjlCDcEWt3zf6i6v6PUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz",
          }
        );
      }
      // OPTIONAL CONFIGURATION

      // Customized video player parameters
      this.player.source = {
        sources: [
          {
            src: this.event.sources[0].url,
            type: "application/x-mpegurl",
            lowLatency: false,
          },
        ],
        // Poster image
        poster: this.event.player_poster_url,
      };
      this.player.autoplay = true;
      this.player.preload = "auto";

      this.player.addEventListener("destroy", () => {
        this.player = null;
      });

      this.player.addEventListener("sourcechange", () => {
        this.currentSrc = this.player.src;
        this.currentSrcLang = this.event.sources.find(
          (v) => v.url === this.player.src
        ).language_code;
      });
    },
    destroyPlayer() {
      if (this.player) {
        this.player.destroy();
      }
    },

    queryViewers() {
      let date = new Date();
      date.setSeconds(date.getSeconds() - 90);
      let usercount = 9999;
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname || this.$route.params.eventname)
        .collection("viewers")
        .where("updated_at", ">=", date)
        .get()
        .then((querySnapshot) => {
          console.log("query size: " + querySnapshot.size);
          usercount = querySnapshot.size;
          console.log("user count: " + usercount);
        });
    },
    setSource(url) {
      this.player.src = url;
      this.player.play();
    },
  },
  watch: {
    user: function () {
      this.user.isAnonymous ? this.logOut() : null;
      !this.user ? this.$refs["login-modal"].show() : null;
    },
  },
};
</script>
